<template>
  <div>
    <div
      ref="itemFooterTipsRef"
      v-for="(tips, index) in tipsList"
      :key="tips.type"
      class="item-footer-tips"
      v-expose="tips.props?.expose"
    >
      <Icon 
        name="sui_icon_caution_12px_2" 
        size="12px" 
        color="#999"
      />
      <span 
        class="item-footer-tips__text"
        :class="{
          'item-footer-tips__text_ellipsis': tipsFoldable[index],
          'item-footer-tips__text_hidden': !tipsExpanded[index]
        }"
      >
        {{ tips.text }}
      </span>
      <Icon 
        v-if="tipsFoldable[index]"
        :name="tipsExpanded[index] ? 'sui_icon_more_up_12px_2' : 'sui_icon_more_down_12px_2'" 
        size="12px" 
        color="#959595"
        is-responsive-name
        @click="handleToggle(index)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { useMapState, useMapGetters } from 'public/src/pages/checkout/hooks/index.js'
import { isProductRefundable } from 'public/src/pages/checkout/utils.js' 
import { FooterTipsType, NonRefundableType, nonRefundablePriority } from './config.js'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { language } = useMapState(['language'])
const { SiteUID, showCartRefundByAbt } = useMapGetters(['SiteUID', 'showCartRefundByAbt'])

const tipsFoldable = ref([])
const tipsExpanded = ref([])
const itemFooterTipsRef = ref(null)
const tipsList = computed(() => {
  return [
    {
      type: FooterTipsType.ExtraPoints,
      text: language.value.SHEIN_KEY_PWA_21240,
      props: {
        expose: { 
          id: '1-8-5-18', 
          data: { skc_code: props.item.product.goods_sn }
        }
      },
      visible: props.item.product.double_status == 0
    },
    getNonRefundableInfo()
  ].filter(tips => tips.visible)
})

const nonRefundableText = {
  [NonRefundableType.Customized]: language.value.SHEIN_KEY_PWA_33401,
  [NonRefundableType.Category]: language.value.SHEIN_KEY_PWA_33399,
  [NonRefundableType.Promotion]: language.value.SHEIN_KEY_PWA_33400,
  [NonRefundableType.LowGrossMargin]: language.value.SHEIN_KEY_PWA_33400,
  [NonRefundableType.Withdrawal]: language.value.SHEIN_KEY_PWA_33403,
  [NonRefundableType.RefundableWithdrawal]: language.value.SHEIN_KEY_PWA_33402
}

const getNonRefundableInfo = () => {
  // abt 关闭，保持线上逻辑，不细分不可退类型
  if (!showCartRefundByAbt) {
    return {
      type: FooterTipsType.NonRefundable,
      text: language.value.SHEIN_KEY_PWA_15856,
      visible: props.item.return_flag && props.item.return_flag != 0
    }
  }
  const { nonRefundableInfo }  = isProductRefundable(SiteUID.value, props.item)
  const isDe = !!~SiteUID.value.indexOf('de')
  const priority = isDe ? nonRefundablePriority.de : nonRefundablePriority.default
  const type = priority.find(type => nonRefundableInfo[type])
  if (!type) return {}
  return { 
    type, 
    visible: true,
    text: nonRefundableText[type] 
  }
}

const handleToggle = (index) => {
  const expandedArr = [...tipsExpanded.value]
  expandedArr[index] = !tipsExpanded.value[index]
  tipsExpanded.value = expandedArr
}

watch(
  () => tipsList.value, 
  (list, oldList = []) => {
    if (typeof window === 'undefined' || !list?.length || JSON.stringify(list) === JSON.stringify(oldList)) return
    nextTick(() => {
      if (!itemFooterTipsRef.value) return
      tipsFoldable.value = []
      let foldableArr = []
      itemFooterTipsRef.value.forEach((parent, index) => {
        const tipsTextElement = parent.querySelector('.item-footer-tips__text')
        // 如果换行了则需要折叠
        const lineBreak = tipsTextElement.offsetWidth < tipsTextElement.scrollWidth
        foldableArr[index] = lineBreak
      })
      tipsFoldable.value = foldableArr
    })
  },
  {
    deep: true,
    immediate: true
  }
)
</script>

<style lang="less" scoped>
.item-footer-tips {
  padding-top: 16/75rem;
  display: flex;
  line-height: 1.2;
  color: #999;

  &__text {
    margin-left: 8/75rem;
    font-size: 10px;
    white-space: normal;
  }

  &__text_hidden {
    overflow: hidden;
    white-space: nowrap;
  }

  &__text_ellipsis {
    text-overflow: ellipsis;
  }
}
</style>