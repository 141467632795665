<!-- 该组件外层购物袋也有使用，修改时需要注意 -->
<template>
  <li
    v-expose="{
      id: '1-8-3-45',
      data: { index, item: cart, behaviorText }
    }"
    v-tap="{
      id: '1-8-8-6',
      data: { 
        index, 
        item: { 
          ...cart, 
          ...cart.product,
          sale_price: cart.product.salePrice,
          retail_price: cart.product.retailPrice
        }, 
        behaviorText 
      }
    }"
    da-expose-code="placeOrderProductBox"
    class="shopping-bag__item-wrap"
  >
    <div class="item-content mshe-flexbetween">
      <div class="mbag-left">
        <CommonImage
          class="left-img"
          :item="cart"
          :cut-img-width="80"
        >
          <template #others>
            <img
              v-if="isSensitiveGood"
              class="item-sensitive"
              :src="`${PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
            />
            <div
              v-if="isOutStockGood"
              class="goods-out-stock"
            >
              <div class="goods-out-stock__content">
                <i class="iconfont icon-SOLDOUT"></i> <span>{{ language.SHEIN_KEY_PWA_14987 }}</span>
              </div>
            </div>
            <ShoppingBagItemBelt
              class="shopping-bag__item-belt"
              :item="cart"
              :isSheinClubGift="isSheinClubGiftItem"
              :lagreShipInfo="lagreShipInfo"
              :isOutStockGood="isOutStockGood"
              :isGiftGoods="isGiftGoods"
              :isAddBuyGoods="isAddBuyGoods"
              :inDrawer="inDrawer"
            />
          
            <!-- 有付费会员标签，只展示付费会员，不展示其他配套色 -->
            <template v-if="showDiscountLabel">
              <div
                v-if="cart.product.unit_discount > 0"
                :class="[
                  discountLogoByAbt ? 'cart-discount-v2' : 'new-discount',
                  isPremiumMember ? {
                    'premium-member-discount': isPremiumMember,
                  } : {
                    'fs-discount': isFlashDiscount,
                    'fs-new-discount': isFlashNewDiscount,
                    'brand-flash': isBrandFlash
                  },
                  {'club-gift-discount': isSheinClubGiftItem}
                ]"
                aria-hidden="true"
              >
                <span v-if="GB_cssRight">-%{{ cart.product.unit_discount }}</span>
                <span v-else>-{{ cart.product.unit_discount }}%</span>
              </div>
            </template>
            <template v-else>
              <!-- 法国，波兰合规展示 字段不同 -->
              <div
                v-if="!isInversion && cart.product.suggested_unit_discount > 0"
                :class="[
                  discountLogoByAbt ? 'cart-discount-v2' : 'new-discount',
                  isPremiumMember ? {
                    'premium-member-discount': isPremiumMember,
                  } : {
                    'fs-discount': isFlashDiscount,
                    'fs-new-discount': isFlashNewDiscount,
                    'brand-flash': isBrandFlash
                  }
                ]"
                aria-hidden="true"
              >
                <span v-if="GB_cssRight">-%{{ cart.product.suggested_unit_discount }}</span>
                <span v-else>-{{ cart.product.suggested_unit_discount }}%</span>
              </div>
            </template>
          </template>
        </CommonImage>
      </div>
      <div
        class="mbag-right mshe-flexbetween"
      >
        <div>
          <div
            v-if="chemicalsIds.includes(cart.product.goods_sn)"
            class="warning-tip title-inner"
            @click.stop="showP65Warning(cart.product.goods_sn)"
          >
            <img :src="IMG_LINK['warning']" />
            <span>{{ language.SHEIN_KEY_PWA_20813 }}</span>
          </div>
          <div class="title-wrapper">
            <img
              v-if="goodsNameBeforeLabelIcon"
              class="black-five-label"
              :src="goodsNameBeforeLabelIcon"
              alt=""
            />
            <div
              class="gd-name"
              v-html="cart.product.goods_name"
            ></div>
          </div>
          <div
            v-if="skuSaleAttrText(cart)"
            class="gd-size-color"
          >
            <span
              v-if="cart.product.main_sale_attr_show_mode == 2 && cart.product.color_image && cart.product.relatedColor && cart.product.relatedColor.length"
              class="value"
            >
              <img
                :src="transformImg({img: cart.product.color_image})"
                class="color-img"
              />
            </span>
            <span class="attr">{{ skuSaleAttrText(cart) }}</span>
          </div>
          <div
            v-else
            class="gd-size-color"
          >
            <template v-if="cart.product.size || cart.product.color_image">
              <span class="value">{{
                getProductSize({ 
                  abt: defaultLocalSizeByAbt,
                  sizeAttr: cart.product.sku_sale_attr?.find(attr => +attr.attr_id === 87),
                  sizeRuleList: cart.attr_value_location?.size_rule_list,
                  cacheLocalCountry
                })  
              }}</span>
              <template v-if="cart.product.size && cart.product.color_image">
                /
              </template>
              <span
                v-if="cart.product.color_image"
                class="value"
              >
                <img
                  :src="transformImg({img: cart.product.color_image})"
                  class="color-img"
                />
              </span>
            </template>
            <template v-else>
              <span></span>
            </template>
          </div>
          <CartItemBehavior
            :language="checkoutLanguage"
            :label="cart.product.showBehaviorLabel"
            style="margin-top: .16rem"
          />
          <!-- 免邮，quickship，赠品 三个标签特殊处理需要展示，其余活动标签不展示 -->
          <cart-item-product-tag
            v-if="!isSheinClubGiftItem"
            :language="checkoutLanguage"
            :item="cart"
            :is-new-cart="true"
            :free-lang="language.SHEIN_KEY_PWA_18337"
            :abt-params="checkoutAbtInfo"
            page="checkout"
            :showQuickShip="!!(cart.quick_ship && showQuickShipByAbt)"
            :showFreeShipping="!IS_RW && +cart.freight_free_rule_id > 0"
            :isSuggested="isSuggested"
            :show-evolu-shein="showEvoluShein"
            style="margin-top: .16rem"
          />
        </div>
        <div class="gd-info">
          <div class="checkout-shopping-bag__price">
            <template v-if="showDiscountType">
              <!-- 有付费会员标签，不展示其他标签 -->
              <Icon 
                v-if="isPremiumMember || isSheinClubGiftItem"
                name="sui_icon_club_logo_fill_14px" 
                size="14px" 
                :color="discountLogoByAbt ? '#873C00' : '#C96E3F'"
              />
              <template v-else>
                <i
                  v-if="promotionIcon"
                  class="suiiconfont"
                  :class="[promotionIcon, promotionIcon == 'sui_icon_flashsale_15px' ? 'iconfont-yellow' : 'iconfont-red']"
                ></i>
                <Icon 
                  v-else-if="isBrandFlash"
                  name="sui_icon_brandsale_15px" 
                  size="16px"
                  color="#FFCD94" 
                />
              </template>
              <em
                v-if="!IS_RW && isVipItem"
                class="vip-exclusive-tag_color-black"
                :class="[GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']"
              ></em>
            </template>
           
            <vip-price 
              v-if="IS_RW && isVipItem"
              :price="cart.unitPrice.amountWithSymbol" 
            />

            <div 
              v-else-if="isSuggestedInDe"
              class="checkout-shopping-bag__price-detail"
              :class="[
                ...priceClassName,
                'column-direction'
              ]"
            >
              <div class="checkout-shopping-bag__price-content">
                <span class="checkout-shopping-bag__price-unit">
                  {{ getPriceByAbt }}
                </span>
                <template v-if="showDiscountInDe">
                  <del 
                    v-if="[1, 3].includes(productPriceScene)"
                    class="checkout-shopping-bag__price-delete"
                  >
                    {{ crossPrice }}
                  </del>
                  <s-popover
                    v-if="[3].includes(productPriceScene)"
                    ref="priceTipsRef"
                    v-model="priceTipsVisible"
                    class="checkout-shopping-bag__tips"
                    :append-to-body="!inDrawer"
                    :prop-style="{
                      width: '6.4rem'
                    }"
                    :placemen="popoverPlacement"
                    show-close-icon
                    @opened="handlePopoverOpened()"
                    @beforeClose="handlePopoverClose()"
                  >
                    <Icon
                      slot="reference"
                      name="sui_icon_info_12px_2" 
                      size="12px" 
                      color="#959595"
                    />
                    <div class="checkout-shopping-bag__tips-content">
                      {{ language.SHEIN_KEY_PWA_31354 }}
                    </div>
                  </s-popover>
                </template>
              </div>
              <div
                v-if="[1, 5].includes(productPriceScene)"
                class="cross-text"
                v-html="crossPriceTipsInDe"
              ></div>
            </div>
            <span
              v-else
              class="checkout-shopping-bag__price-detail"
              :class="priceClassName"
            >
              {{ getPriceByAbt }}
              <template v-if="!isInversion && isShowDelPrice">
                <del v-if="isSuggested != 'special'">
                  {{ crossPrice }}
                </del>
                <span 
                  v-else
                  class="cross-text"
                  v-html="crossPriceTips"
                ></span>
              </template>
            </span>
          </div>

          <InputNumber 
            v-if="isShowEditBtn"
            class="checkout-shopping-bag__input"
            :item="cart"
            :isLastGoods="isLastGoods"
            source="inner"
            :isSheinClubGiftItem="isSheinClubGiftItem"
            @click.native.stop
          />
          <div
            v-else
            class="gd-quantity"
            :class="{'gd-quantity-welfare': +(cart.quantity >= 2)}"
          >
            <span class="value">x{{ cart.quantity }}</span>
          </div>
        </div>
      </div>
    </div>
    <ItemFooterTips :item="cart"/>
    <s-alert
      v-if="isCustomCart"
      v-tap="{
        id: '1-11-1-174', 
        data: customAnalysis
      }"
      class="shopping-bag__item-custom"
      type="info" 
      :jumpable="true"
      @click.native.stop="openPreviewDialog"
    >
      <p>
        {{ language.SHEIN_KEY_PWA_26444 }}
      </p>
    </s-alert>
    <div 
      v-if="largeShipTips"
      class="gd-footer large-tip"
    >
      <Icon
        :name="lagreShipInfo.largeShipIcon"
        :size="'12px'"
        :color="'#198055'"
      />
      <span 
        class="large-tip-text" 
        v-html="largeShipTips"
      >
      </span>
    </div>
  </li>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { transformImg } from '@shein/common-function'

import VipPrice from 'public/src/pages/components/product/VipPrice.vue'
// import CartItemProductTag from 'public/src/pages/cartNew/components/list/newCartItem/CartItemProductTag.vue'
import CartItemProductTag from 'public/src/pages/cartNew/components/list/item/CartItemProductTag.vue'
import InputNumber from 'public/src/pages/checkout/vue_tpls/shopping_bag/InputNumber.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'

import { daEventCenter } from 'public/src/services/eventCenter'
import { defaultP65TipsContent, chemicalsIdsEvt } from '../tools/p65util.js'
import { isPromitionGoods, handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'
import customDetailDialog from 'public/src/pages/common/customDetailDialog/index.js'
import CartItemBehavior from 'public/src/pages/cartNew/components/list/item/CartItemBehavior.vue'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import { getProductSize } from 'public/src/pages/checkout/utils.js'
import CommonImage from 'public/src/pages/checkout/components/CommonImage.vue'
import ItemFooterTips from 'public/src/pages/checkout/vue_tpls/shopping_bag/ItemFooterTips.vue'
import ShoppingBagItemBelt from 'public/src/pages/checkout/vue_tpls/shopping_bag/ShoppingBagItemBelt.vue'

const daEventExpose = daEventCenter.getExposeInstance()
daEventCenter.addSubscriber({ modulecode: '1-8-3' })
daEventCenter.addSubscriber({ modulecode: '1-8-5' })
daEventCenter.addSubscriber({ modulecode: '1-8-8' })

const { IMG_LINK, GB_cssRight, IS_RW, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'ShoppingBagItem',
  components: {
    VipPrice,
    CartItemProductTag,
    CartItemBehavior,
    InputNumber,
    Icon,
    SPopover,
    CommonImage,
    ItemFooterTips,
    ShoppingBagItemBelt
  },
  props: {
    cart: {
      type: Object,
      default: () => {}
    },
    showArrivalPriceByAbt: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    drawerOpened: {
      type: Boolean,
      default: false
    },
    // 是否为详情弹窗里的商品行，用于区分外层的商品明细样式
    inDrawer: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  data() {
    return {
      IMG_LINK,
      IS_RW,
      GB_cssRight,
      PUBLIC_CDN,
      popoverPlacement: 'bottom',
      priceTipsRef: null,
      priceTipsVisible: false,
      priceTipsTimer: null
    }
  },
  computed: {
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'checkoutAbtInfo', 
      'defaultShipping',
      'shoppingBagModalShow',
      'locals',
      'price',
    ]),
    ...mapGetters('checkout', [
      'showQuickShipByAbt', 
      'chemicalsIds',
      'canEditShoppingbag',
      'shoppingBagCrossedPriceByAbt',
      'discountLogoByAbt',
      'isSuggested',
      'switchOnEvoluSHEIN',
      'EVOLU_SHEIN_ID',
      'isSuggestedInDe',
      'defaultLocalSizeByAbt',
      'cacheLocalCountry',
      'isSiteMallList',
      'largeShipExtendsInfo',
    ]),
    largeShipTips() {
      if(!this.lagreShipInfo.largeShipTimeDesc || this.lagreShipInfo.isLargeShipGood != 1) return ''
      return this.lagreShipInfo.largeShipTitle + ':' + this.lagreShipInfo.largeShipTimeDesc
    },
    lagreShipInfo() {
      const { largeShipTimeDesc = '', largeShipTitle = '', isLargeShipGood = 0, largeShipType = '', largeShipIcon = '' } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.cart.mall_code, 
        item: this.cart,
      })
      return {
        largeShipTimeDesc,
        largeShipTitle,
        isLargeShipGood,
        largeShipType,
        largeShipIcon,
      }
    },
    isAddBuyGoods() {
      return this.cart?.product?.product_promotion_info?.some(promo => [2].includes(+promo.promotion_product_type))
    },
    isGiftGoods() {
      const cartGift = this.cart?.product?.product_promotion_info?.some(promo => [1].includes(+promo.promotion_product_type))
      const checkoutGift = this.cart?.promotion_type_id == '1000'

      return cartGift || checkoutGift
    },
    showEvoluShein() {
      return this.switchOnEvoluSHEIN && this.cart?.rules?.includes(this.EVOLU_SHEIN_ID)
    },
    isSheinClubGiftItem() {
      let isSheinClubGift = false
      this.cart?.product?.product_promotion_info?.forEach(promotion => {
        // 根据promotion ID查促销接口获得promotion_type=4且promotion_logo_type如果为8就是会员赠品商品）
        // 且promotion_product_type === 1
        if (promotion.type_id == 4 && promotion.promotion_logo_type === 8 && promotion.promotion_product_type == '1') {
          isSheinClubGift = true
        }
      })
      return isSheinClubGift
    },
    getPriceByAbt() {
      let originPrice = this.cart?.unitPrice?.amountWithSymbol
      if(!this.showArrivalPriceByAbt) return originPrice
      
      let actualPriceObj = this.price.cart_sub_infos?.find(item => item.cart_id == this.cart.id) || {}
      const { single_sub_total } = actualPriceObj
      if(!single_sub_total) return originPrice
      return single_sub_total.amountWithSymbol
    },
    isShowEditBtn() {
      // abt开启后只要是会员赠品即展示编辑按钮
      if(this.canEditShoppingbag && this.isSheinClubGiftItem) {
        return true
      }
      return this.canEditShoppingbag && !isPromitionGoods(this.cart) && !this.isOutStockGood
    },
    SiteUID() {
      return this.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    },
    crossPriceTips() {
      return `<del>${this.crossPrice}</del>` + this.language.SHEIN_KEY_PWA_30769
    },
    // 德国合规中，30天最低价提示文案
    crossPriceTipsInDe() {
      const price = this.cart.product.suggested_sale_price?.amountWithSymbol
      return `<del>${price}</del>` + this.language.SHEIN_KEY_PWA_31353
    },
    crossPrice() {
      if (this.cart.product.insurable == 1 && this.cart.product.sale_price_before_insured)
        return this.cart.product.sale_price_before_insured.amountWithSymbol
      // 法国，波兰合规展示 字段不同，德国合规展示原价
      if (!!this.isSuggested && !this.isSuggestedInDe) return this.cart.product.suggested_sale_price?.amountWithSymbol
      return this.cart.product.retailPrice?.amountWithSymbol
    },
    // 价格是否倒挂，倒挂时不展示折扣和划线价
    isInversion() {
      return !!this.isSuggested && this.cart.is_inversion == 1
    },
    /**
     * 展示活动颜色、活动标签、活动icon条件
     * 1.非法国、波兰、德国合规
     * 2.德国合规：特定场景展示
     * 3.法国、波兰合规
     *  3.1 商品价格未倒挂
     *  3.2 倒挂但是参与特定活动（B类活动、付费会员）
     */
    showDiscountType() {
      if (this.isSuggestedInDe) return this.showDiscountInDe
      return !this.isSuggested || !this.isInversion || this.cart.isPromotionB || this.isPremiumMember || this.isSheinClubGiftItem
    },
    // 商品行价格场景：输出参考德国合规需求TR-36999
    productPriceScene() {
      // 30天最低价
      const LowestPrice = +this.cart.product.suggested_sale_price?.amount
      // 原价
      const retailPrice = +this.cart.product.retailPrice?.amount
      // 促销活动后价格，即售价
      const salePrice = +this.cart.unit_price_before_coupon?.amount

      let priceScene

      if (LowestPrice > 0) {
        if (LowestPrice < retailPrice) {
          priceScene = retailPrice > salePrice ? 1 : 2
        } else if (LowestPrice === retailPrice) {
          priceScene = retailPrice > salePrice ? 3 : 4
        } else {
          priceScene = 5
        }
      } else {
        priceScene = 6
      }

      return priceScene
    },
    // 德国合规需求中展示折扣的场景
    showDiscountInDe() {
      return [1, 3, 5].includes(this.productPriceScene)
    },
    showDiscountLabel() {
      // 德国合规分情况展示折扣标签
      if (this.isSuggestedInDe) return this.showDiscountInDe
      return !this.isSuggested
    },
    outStockCarts() {
      if (this.checkout?.mall_caculate_info?.outStockCarts) {
        const _obj = {}
        this.checkout.mall_caculate_info.outStockCarts.forEach((item) => {
          _obj[item.id] = true
        })
        return _obj
      } else {
        return {}
      }
    },
    isOutStockGood() {
      return this.outStockCarts[this.cart.id] || false
    },
    goodsNameBeforeLabelIcon() {
      return this.cart.product?.blackFiveSignBeforeGoodsNameInfo?.goodsNameLabel?.icon || ''
    },
    promotionIcon() {
      const promotionTypeIds = this.cart?.promotionTypeIds || []
      const promotionLogoTypeList = this.cart?.promotionLogoTypeList || []
      if(promotionTypeIds.includes(3) ||
        (promotionTypeIds.includes(31) && promotionLogoTypeList.includes(3))
      ) {
        return 'sui_icon_time_15px'
      } else if(promotionTypeIds.includes(8)){
        return 'sui_icon_phone_15px'
      } else if((promotionTypeIds.includes(10) && this.cart?.flashType == 1) || promotionTypeIds.includes(11)){
        return 'sui_icon_flashsale_15px'
      }
      return ''
    },
    isPremiumMember () {
      return this.cart?.promotionTypeIds?.includes(29)
    },
    priceClassName() {
      if(!this.showDiscountType) return ''

      if (this.isPremiumMember) {
        return this.discountLogoByAbt ? 'goods-price-prime__v2' : 'goods-price-prime'
      } else {
        if(this.isSheinClubGiftItem) return 'club-gift-price'
        let noSame = !!this.isSuggested ? this.cart.unitPrice?.amount != this.cart.product?.suggested_sale_price?.amount : this.cart.unitPrice?.amount != this.cart.product?.retailPrice?.amount
        if (this.isSuggestedInDe) noSame = this.showDiscountInDe
        return [
          {
            'goods-price-red': noSame,
            'font-vip': this.isVipItem
          }
        ]
      }
    },
    isFlashDiscount() {
      const { promotionTypeIds, flashType } = this.cart || {}
      // 闪购活动
      const isFlash = promotionTypeIds?.includes(10)
      // flashType取值：1（普通闪购），2（新人闪购），3（特殊闪购） 4-5 （专区闪购）
      // 常规闪购
      const isNormalFlash = isFlash && flashType == 1
      // 专区闪购
      const isSpecialFlash = isFlash && [4, 5].includes(flashType)
      // 直播闪购
      const isLiveFlash = promotionTypeIds?.includes(11)
      return isNormalFlash || isSpecialFlash || isLiveFlash
    },
    isFlashNewDiscount() {
      const { promotionTypeIds, flashType } = this.cart || {}
      return promotionTypeIds?.includes(10) && [2, 3].includes(flashType)
    },
    customizationInfo() {
      return this.cart.product?.customization_info || {}
    },
    isCustomCart() {
      const preview = this.customizationInfo?.preview || {}
      const canCustoms = this.cart.product?.customization_flag == 1 && (!!preview.effects?.length || !!preview.images?.length || !!preview.texts?.length)
      return !this.IS_RW && canCustoms
    },
    customAnalysis() {
      const preview = this.customizationInfo?.preview || {}
      return {
        images: preview?.images?.length || 0,
        preview: preview?.effects?.length || 0,
        texts: preview?.texts?.length || 0
      }
    },
    isSensitiveGood() {
      let isSensitive
      this.defaultShipping?.forEach(item => {
        if (item.mall_code === this.cart?.mall_code) {
          const info = item.shipping_method.cmmdty_infos?.find(info => (
            info.item_no === this.cart.id
          ))
          isSensitive = !!info
        }
      })
      return isSensitive
    },
    behaviorText() {
      const label = this.cart.product.showBehaviorLabel
      return BehaviorLabel.getSaText(label, this.checkoutLanguage)
    },
    checkoutLanguage(){
      return { ...this.checkout.cartLanguage, ...this.language }
    },
    isVipItem() {
      return this.cart.promotionTypeIds?.includes(12)
    },
    isShowDelPrice() {
      if(!this.isSuggested && !this.shoppingBagCrossedPriceByAbt) return false

      const noSame = !!this.isSuggested ? this.cart.unitPrice?.amount != this.cart.product?.suggested_sale_price?.amount : this.cart.unitPrice?.amount != this.cart.product?.retailPrice?.amount
      return this.isVipItem || noSame || this.cart.product.insurable == 1
    },
    isBrandFlash() {
      const { promotionTypeIds, flashType } = this.cart || {}
      // 闪购活动
      const isFlash = promotionTypeIds?.includes(10)
      return isFlash && flashType == 6
    },
  },
  watch: {
    shoppingBagModalShow: {
      handler (show) {
        // 用户行为标签的埋点上报
        if (show) this.handleBehaviorReport()
      },
      immediate: true
    },
    drawerOpened: {
      handler (opened) {
        if (opened && this.inDrawer) this.initPopoverPlacement()
      },
      immediate: true
    }
  },
  mounted() {
    this.initExposeEvent()
    !this.inDrawer && this.initPopoverPlacement()
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    transformImg,
    isPromitionGoods,
    getProductSize,
    async showP65Warning (goods_sn) {
      let caculate_info = this.checkout?.mall_caculate_info || {}
      let p65Text = await chemicalsIdsEvt(caculate_info, goods_sn)
      this.assignState({
        p65TipsShowText: p65Text || defaultP65TipsContent(this.language),
        p65TipsShow: true
      })
      sa('send', { activity_name: 'click_p65warning' })
      daEventCenter.triggerNotice({
        daId: '1-11-1-238',
        extraData: {
          sku_code: goods_sn
        }
      })
    },
    skuSaleAttrText (item = {}) {
      let result = []
      if(item.product?.sku_sale_attr?.length){
        result = item.product.sku_sale_attr.map(attr => {
          // size
          if (+attr.attr_id === 87) return getProductSize({
            abt: this.defaultLocalSizeByAbt,
            sizeAttr: attr,
            sizeRuleList: item.attr_value_location?.size_rule_list,
            cacheLocalCountry: this.cacheLocalCountry
          })
          return attr.attr_value_name
        })

        !result.length && result.push(' ')
      }
      return ` ${result.join(' / ')}`
    },
    initExposeEvent () {
      this.$nextTick(() => {
        daEventExpose.subscribe({
          keycode: 'placeOrderProductBox`1-8-5-18|1-8-5-21'
        })
      })
    },
    initPopoverPlacement() {
      this.$nextTick(() => {
        const { priceTipsRef } = this?.$refs || {}
        if (!priceTipsRef) return
        const { right = 0, bottom = 0 } = priceTipsRef?.$el?.getBoundingClientRect() || {}
        const rightDistance = window.innerWidth - right
        const bottomDistance = window.innerHeight - bottom
        const placement = bottomDistance > 80 ? 'bottom' : 'top' 
        this.popoverPlacement = rightDistance > 120 ? placement : `${placement}-end`
      })
    },
    openPreviewDialog() {
      customDetailDialog.open({
        ...this.customizationInfo?.preview,
      })
    },
    handleBehaviorReport() {
      daEventCenter.triggerNotice({
        daId: '1-11-3-5',
        extraData: {
          show_behavior_label: this.behaviorText
        }
      })
    },
    handlePopoverOpened() {
      this.priceTipsTimer = setTimeout(() => {
        this.priceTipsVisible = false
      }, 3000)
    },
    handlePopoverClose() {
      clearTimeout(this.priceTipsTimer)
    },
    getGoodsCutImg(url) {
      if (!this?.cutImg) {
        return transformImg({ img: url })
      }

      return this.cutImg(url, 750, {  cutTo: 240, max: 240 })
    }
  }
}
</script>
<style lang="less" scoped>
.shopping-bag {

  &__item-wrap {
    padding-bottom: 0.32rem;
  }

  &__item-custom {
    background: #F6F6F6;
    padding: 12/75rem;
    .padding-r(0);
    margin-top: 16/75rem;
  }

  &__item-belt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.item-content {
  padding: .32rem 0 0;
  width: 100%;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.mixin-discount-tag() {
  position: absolute;
  right: auto;
  text-align: center;
  color: @color_white_mirror;
  direction: ltr;
  .font-dpr(24px);
  & when (@IS_SH) {
    min-width: 2.75em;
    line-height: 1.35;
    top: 4px;
    .left(0);
    background: #333;
  }
  & when (@IS_RW) {
    padding: 0 .1rem;
    top: 0;
    .left(0);
    line-height: .48rem;
    background: @sui_color_main;
    color: @sui_color_white;
  }
}

.mbag-left {
  .margin-r(.32rem);
  .left-img {
    width: 2rem;
    height: 2.666rem;
    position: relative;
    .club-gift-wrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: @sui_color_club_rosegold_dark1;
      color: #ffffff;
      text-align: center;
    }
    .item-sensitive {
      width: 32/75rem;
      height: 32/75rem;
      position: absolute;
      top: 8/75rem;
      right: 8/75rem;
    }
  }
  .new-discount {
    .mixin-discount-tag();
    & when (@IS_SH) {
      &.fs-discount {
          background:@sui_color_flash;
          color: #333;
      }
      &.premium-member-discount {
        background: #FFE2D3;
        color: #873C00;
      }
      &.brand-flash {
        background: @sui_color_discount;
        color: @sui_color_white;
      }
    }
    & when (@IS_RW) {
      &.fs-discount {
        background: @sui_color_main;
        color: @sui_color_white;
      }
      font-family: Adieu-Bold, Adieu;
      font-weight: bold;
      font-size: 9px;
    }
    &.fs-new-discount {
      background: @sui_color_discount;
      color: @sui_color_white;
    }
  }
  .cart-discount-v2 {
    .mixin-discount-tag();
    background: @sui_color_discount;
    color: @sui_color_white;
    & when (@IS_SH) {
      &.premium-member-discount {
        background: @sui_color_club_rosegold_dark1;
        color: @sui_color_white;
      }
      &.club-gift-discount {
        background: @sui_color_club_rosegold_dark1;
        color: @sui_color_white;
      }
    }
  }

  .goods-out-stock{
    position: absolute;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    .goods-out-stock__content{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      width: 100%;
      > i {
        color: #fff;
        .font-dpr(48px);
        line-height: initial;
      }
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        .font-dpr(24px);
      }
    }
  }
}
.gd-footer{
  padding-top: 16/75rem;
  color: #999;
  white-space: normal;
}

.extra-point-tip{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.large-tip {
  color: @sui_color_highlight;
  .font-dpr(20px);
}

.mbag-right {
  flex: 1;
  flex-direction: column;
  min-height: 2.666rem;
  min-width: 0;
  .iconfont-red {
    color: @sui_color_promo;
    .font-dpr(30px);
  }
  .iconfont-yellow {
    color: @sui_color_flash;
    .font-dpr(30px);
  }
  
  .title-wrapper {
    display: flex;
    align-items: center;
    height: 0.373rem;
    margin-bottom: 0.08rem;
    .black-five-label {
      width: 1.28rem;
      height: 0.32rem;
      margin-right: 0.107rem;
    }
    .gd-name {
      color: #666;
      .font-dpr(24px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 0.373rem;
      line-height: 0.39rem;
    }
  }
  
  .gd-size-color {
    display: inline-block;
    color: #666666;
    .font-dpr(24px);
    .line-camp(1);
    width: 7rem;
    .color-img {
      width: .32rem;
      height: .32rem;
      border-radius: 50%;
      vertical-align: text-top;
    }
  }
}

.gd-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
  .font-dpr(24px);
  margin-top: .16rem;
  .gd-quantity{
    .value{
      font-weight: 400;
      .font-dpr(24px);
      color: #333333;
    }
  }
  .gd-quantity-welfare {
    .value {
      color: @sui_color_welfare;
    }
  }
}

.checkout-shopping-bag__price {
  display: inline-flex;
  align-items: center;
  flex: 1;
  .margin-r(12/75rem);
  max-width: calc(100% - 1.974rem);
  .checkout-shopping-bag__price-detail {
    color: #222222;
    .font-dpr(28px);
    font-weight: bold;
    line-height: .453rem;
    width: 100%;
    min-width: 0;
    display: inline-flex;
    &.goods-price-red {
      color: @sui_color_discount;
    }
    &.font-vip {
      color: #A78A45;
    }
    &.goods-price-prime {
      color: #C96E3F;
    }
    &.goods-price-prime__v2 {
      color: @sui_color_club_rosegold_dark1;
    }
    &.club-gift-price {
      color: @sui_color_club_rosegold_dark1;
    }
    &.column-direction {
      flex-direction: column;
    }
    del {
      color: #959595;
      .font-dpr(20px);
      .padding-l(4/75rem);
    }

    .cross-text {
      .font-dpr(20px);
      .padding-l(4/75rem);
      color: @sui_color_gray_dark3;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
    }
  }
  .checkout-shopping-bag__price-content {
    display: inline-flex;
    flex-shrink: 0;
    align-items: baseline;
  }
  .checkout-shopping-bag__price-unit {
    word-break: break-all;
    flex-shrink: 0;
    max-width: 100%;
  }
  .checkout-shopping-bag__price-delete {
    color: @sui_color_gray_dark3;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
}

.checkout-shopping-bag__input {
  white-space: nowrap;
}

.warning-tip {
	&.title-inner {
		margin-bottom: 0.08rem;
	}
	width: 1.707rem;
	background: rgba(255, 231, 231, 0.7);
	border: 1px solid rgba(255, 65, 28, 0.6);
	display: flex;
	align-items: center;
	.padding-l(0.053rem);
	& when(@IS_RW) {
		background: #FFF6F4;
		border: 1px solid #E34E60;
	}
	text-align: center;
	font-size: 0;
	img {
		height: 0.267rem;
		width: auto;
		margin-right: 1px;
		vertical-align: sub;
	}
	span {
		display: inline-block;
		line-height: 0.4rem;
		text-transform: uppercase;
		font-size: 0.533rem;
		font-weight: bold;
		transform: scale(0.5);
		.transform-origin-l();
		color: #FF411C;
		& when(@IS_RW) {
			color: #E34E60;
		}
	}
}
</style>
<style lang="less">
@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';

.vip-exclusive-tag,
.vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 3.23rem .77rem;
  width: .85rem;
  height: .27rem;
  &_color-yellow {
    background-position: -1.45rem -.25rem;
  }
  &_color-black {
    background-position: -.48rem -.25rem;
  }
}
.vip-exclusive-tag {
  background-image: url(@s3_icon);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_icon_ar);
  vertical-align: -0.026667rem;
}

.checkout-shopping-bag__tips {
  padding-left: 4/75rem;
}
.checkout-shopping-bag__tips-content {
  max-width: 480/75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.checkout-shopping-bag__price-detail {
  .cross-text {
    del {
      .padding-r(4/75rem);
    }
  }
}
</style>
