<template>
  <swiper-container
    ref="benefitRef"
    init="false"
    destroy-on-disconnected="false"
    class="shopping-bag-benefit"
  >
    <swiper-slide
      v-for="item in benefitList"
      :key="item.type"
      class="shopping-bag-benefit__item"
    >
      <Icon
        :name="item.icon"
        size="12"
      />
      <span 
        class="shopping-bag-benefit__item-text"
        :style="{ 'font-size': `${textSize}px` }"
      >
        {{ item.text }}
      </span>
    </swiper-slide>
  </swiper-container>
</template>

<script setup>
import { watch, nextTick, ref } from 'vue'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'

import { Icon } from '@shein-aidc/icon-vue2'

typeof window !== 'undefined' && register()

const props = defineProps({
  benefitList: {
    type: Array,
    default: () => []
  },
  textSize: {
    type: [String, Number],
    default: '12'
  },
})

const benefitRef = ref(null)

const initSwiper = () => {
  if (benefitRef.value) {
    Object.assign(benefitRef.value, {
      direction: 'vertical',
      modules: [Autoplay],
      autoplay: {
        delay: 1500,
      },
      loop: true,
    })
    benefitRef.value.initialize()
  }
}

watch(
  () => props.benefitList,
  () => {
    if (typeof window === 'undefined') return
    if (benefitRef.value?.swiper) {
      nextTick(() => {
        benefitRef.value.swiper.update()
        benefitRef.value.swiper.autoplay.start()
      })
    } else {
      nextTick(() => initSwiper())
    }
  },
  {
    deep: true,
    immediate: true
  }
)
</script>

<style lang="less" scoped>
.shopping-bag-benefit {
  height: 28/75rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;

  &__item {
    height: 100%;
    font-size: 12px;
    font-weight: 510;
    color: #FA6338;
    display: flex;
    align-items: center;
  }

  &__item-text {
    margin-left: 4/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
