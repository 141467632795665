import { computed } from 'vue'
import { template } from '@shein/common-function'
import useLowStock from 'public/src/pages/checkout/hooks/useLowStock.js'

export default (props) => {
  const TipsType = {
    // 即将售罄
    SoldOut: 'soldout',
    // 低价
    CheaperPrice: 'cheaprice'
  }

  const ReportType = {
    // 当前没有可用标签，且ABT实验结果为展示
    OnlyAbt: 1,
    // 当前没有可用标签，且ABT实验结果为不展示
    Neither: 2,
    // 当前有可用标签，且ABT实验结果为展示
    Both: 3,
    // 当前有可用标签，且ABT实验结果为不展示
    OnlyData: 4
  }

  const { abt = {}, carts = [], language } = props
  const { outOfStockTips, shoppingBagTipsPriority } = abt

  
  const benefitMap = computed(() => {
    let benefitMap = {}
    Object.values(TipsType).forEach(type => {
      switch(type) {
        case TipsType.SoldOut: {
          const { lowStockProducts } = useLowStock({ abt: outOfStockTips, carts })
          const quantity = lowStockProducts.value.length
          if (quantity === 0) break
          benefitMap[type] = {
            type: TipsType.SoldOut,
            quantity,
            icon: 'sui_icon_hourglass_12px_2',
            text: template(quantity, language.SHEIN_KEY_PWA_33205)
          }
          break
        }
        case TipsType.CheaperPrice: {
          const quantity = carts?.filter(cart => +cart.reduce_price?.reduceAmount?.amount > 0).length
          if (quantity === 0) break
          benefitMap[type] = {
            type: TipsType.CheaperPrice,
            quantity,
            icon: 'sui_icon_decrease_12px',
            text: template(quantity, language.SHEIN_KEY_PWA_33246)
          }
          break
        }
        default:
          break
      }
    })
    return benefitMap
  })

  const benefitList = computed(() => {
    return shoppingBagTipsPriority?.map((key) => (
      benefitMap.value[key.toLowerCase()]
    )).filter(item => !!item) || []
  })

  const reportType = computed(() => {
    const hasBenefit = !!Object.values(benefitMap.value).length
    const hasAbt = !!shoppingBagTipsPriority.length
    if (hasBenefit) {
      return hasAbt ? ReportType.Both : ReportType.OnlyData
    } else {
      return hasAbt ? ReportType.OnlyAbt : ReportType.Neither
    }
  })

  return { benefitList, reportType }
}
